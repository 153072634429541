<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    :value="value"
    @input="$emit('input', $event)"
    @confirm="$emit('confirm')"
  >
    <div data-testid="active-dialog-content">
      <strong class="d-block">Trial status</strong>
      Changed from
      <strong>{{ $t('patient-trial-status.' + patient.currentState) }}</strong>
      to
      <strong>
        {{ $t('patient-trial-status.activated') }}
      </strong>
      <WarningBox
        expand
        class="mt-2 mb-1"
        v-if="isPatientCompleted || prescriptionsEnabled"
        data-testid="active-dialog-warning"
      >
        You are setting a patient back to
        <strong>
          {{ $t('patient-trial-status.activated') }}
        </strong>
        state. <br />

        <strong
          v-if="isPatientCompleted"
          data-testid="active-dialog-app-activation"
          >Please make sure to activate an app for the patient.</strong
        >

        <div
          class="mt-6"
          v-if="prescriptionsEnabled"
          data-testid="active-dialog-latest-prescription"
        >
          Latest prescription for the patient was made on:
          <v-chip label class="date-chip" color="#E2EFFA">
            <v-skeleton-loader
              v-if="isLatestPrescriptionDateLoading"
              type="text"
              width="100"
              :loading="true"
            />
            <DateFormat v-else :value="latestPrescriptionDate" />
          </v-chip>
          <strong>
            Please consider whether a new dose prescription is necessary.
          </strong>
        </div>
      </WarningBox>
    </div>
  </BaseConfirmDialog>
</template>

<script>
import Vue from 'vue'
import prescriptionService from '@/services/prescription-service'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'

import BaseConfirmDialog from '../_BaseConfirmDialog'
import DateFormat from '@/components/DateFormat'
import WarningBox from '@/components/WarningBox'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import loadingState from '@/constants/loadingState'

export default {
  name: 'ActiveDialog',
  mixins: [trialSettingsGettersMixin, patientSettingsMixin],
  props: {
    value: { type: Boolean, required: true },
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
  },
  components: { BaseConfirmDialog, DateFormat, WarningBox },
  data() {
    return {
      latestPrescriptionDate: null,
      latestPrescriptionDateLoading: loadingState.INITIAL,
    }
  },
  computed: {
    prescriptionsEnabled() {
      return this.trialFeatureFlags(this.patient.patientNumber)
        .prescriptionsEnabled
    },
    isLatestPrescriptionDateLoading() {
      return this.latestPrescriptionDate === loadingState.LOADING
    },
  },
  methods: {
    loadPrescriptions() {
      this.latestPrescriptionDateLoading = loadingState.LOADING

      prescriptionService
        .getCurrentPrimaryDose(this.patient.patientNumber)
        .then(response => {
          this.latestPrescriptionDateLoading = loadingState.LOAD_SUCCEEDED

          const prescriptionDates = response
            .map(prescription => {
              return prescription.doses.map(
                dose => new Date(dose.doneAt.utcTime)
              )
            })
            .flat()
          this.latestPrescriptionDate = new Date(Math.max(...prescriptionDates))
        })
        .catch(error => {
          this.latestPrescriptionDateLoading = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    if (this.prescriptionsEnabled) {
      this.loadPrescriptions()
    }
  },
}
</script>

<style lang="scss" scoped>
.date-chip {
  display: block !important;
  width: fit-content;
  margin-bottom: 16px;
}
</style>
